import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import Tag from 'ui/elements/Tag';
import _map from 'lodash/map';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { slugify } from 'utils/helpers';
import { format } from 'utils/date';
import ContentBox from 'widgets/ContentBox';
import { useNavigation } from 'widgets/Link';
import { InlineList } from 'components/Layout/styles';


const S = {};
S.Date = styled.time`
  font-size: 85%;
  display: block;
  font-style: italic;
  margin-bottom: 8px;
`;

S.Footer = styled.footer`
  padding-top: 15px;
`;

const NewsBox = ({
  news,
}) => {
  const navigate = useNavigation();
  const { language } = useI18next();

  return (
    <article>
      <S.Date dateTime={news.date}>{format(news.date, language)}</S.Date>

      <ContentBox blocks={news._rawContent} />

      <Row align='center' component={S.Footer}>
        <Col xs={12}>
          <InlineList>
            {
              _map(news.topics, (topic, i) => (
                <Tag
                  key={i}
                  value={topic}
                  onClick={() => navigate(`/news/${slugify(topic)}`)}
                />
              ))
            }
          </InlineList>
        </Col>
      </Row>
    </article>
  )
};

export default NewsBox;
