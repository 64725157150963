import React from 'react'
import { localize } from 'utils/localize'

const withLocalization = (PageComponent) => ({
  data,
  pageContext,
  ...rest
}) => {
  const localized = localize(data, pageContext.language)

  return (
    <PageComponent
      data={localized}
      pageContext={pageContext}
      {...rest}
    />
  )
};

export default withLocalization
