import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-grid-system';
import Button from 'ui/elements/Button'
import Heading from 'ui/elements/Heading'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Contact from 'components/shared/Contact';
import Seo from 'components/shared/Seo';
import { palette as openProgramsPalette } from 'modules/OpenPrograms/utils';
import { palette as customizedProgramsPalette } from 'modules/CustomizedPrograms/utils';
import { palette as conferencesPalette } from 'modules/Conferences/utils';

import { HeroPolygon, HeroCorner } from '../Hero'
import * as S from './styles';

import { BasePageFragment, CtaDataFragment } from '../../../generated/graphql-operations'
import { graphql } from 'gatsby'
import { Maybe } from '../../../generated/graphql-types'
import Link from 'widgets/Link'
import { useGetInternalLink } from 'utils/helpers'

const CtaAction: React.FC<{
  cta: CtaDataFragment
}> = ({ cta }) => {
  const [t] = useTranslation("common")

  const internalLink = useGetInternalLink(cta.internalLink)

  if (!cta.internalLink && !cta.externalLink) {
    return null
  }

  if (cta.externalLink) {
    return <Button
      as="a"
      href={cta.externalLink}
      variant="contained"
      color="shade"
      iconName="arrow-right"
    >
      {t("findOutMore")}
    </Button>
  }

  return <Button
    as={Link}
    to={internalLink}
    variant="contained"
    color="shade"
    iconName="arrow-right"
  >
    {t("findOutMore")}
  </Button>
}

const PageLayout: React.FC<{
  sidebar?: React.FC<BasePageFragment>
  heroProps: any
  data: BasePageFragment & { palette?: string | null }
  title?: string
  cta?: Maybe<CtaDataFragment>
}> = ({
  children,
  sidebar: Sidebar,
  heroProps: heroOverride,
  cta,
  ...layoutProps
}) => {
  const { i18n } = useTranslation();

  const contact = layoutProps.data?.contact;
  const hasSidebar = !!Sidebar && typeof Sidebar === 'function';
  const { type: heroType, ...heroProps } = heroOverride;

  const contentTitle = useMemo(() => {
    const titleProp = layoutProps.title;
    const titleData = layoutProps.data?.title?.localized;

    if (titleProp) return titleProp;
    if (titleData) return titleData;

    return undefined;
  }, [layoutProps])

  const pageTitle = useMemo(() => {
    return layoutProps.data?.meta?.title?.[i18n.language as "de" | "en"] ?? contentTitle
  }, [contentTitle, layoutProps, i18n]);

  const pagePalette = useMemo(() => {
    const palette = layoutProps.data?.palette;
    switch (palette) {
      case 'open-programs': return openProgramsPalette;
      case 'customized-programs': return customizedProgramsPalette;
      case 'conferences': return conferencesPalette;
      default: return undefined;
    }
  }, [layoutProps]);

  const description = useMemo(() => {
    const description = layoutProps.data?.meta?.description

    return description?.[i18n.language as "de" | "en"] ?? description?.en
  }, [layoutProps, i18n])

  const HeroComponent = useMemo(() => {
    if (heroType === 'polygon') return HeroPolygon
    if (heroType === 'corner') return HeroCorner
  }, [heroType])

  return (
    <S.PageLayout>
      <Seo
        title={pageTitle}
        description={description ?? undefined}
      />

      {!!HeroComponent && <HeroComponent
        data={layoutProps.data}
        color={heroType === 'polygon' ? pagePalette?.variant : undefined}
        title={cta?.text}
        action={!!cta && <CtaAction cta={cta} />}
        {...heroProps}
      />}

      <Container component={S.MainContainer} fluid={true} md={true} sm={true}>
        {
          contentTitle && (
            <Row>
              <Col>
                <Heading level={1} color={pagePalette?.accent || 'primary'}>
                  {contentTitle}
                </Heading>
              </Col>
            </Row>
          )
        }
        <Row>
          {
            Sidebar && hasSidebar && (
              <Col
                xs={12}
                md={4}
                xl={3}
                //@ts-ignore
                component='aside'>
                <Sidebar {...layoutProps.data}/>
              </Col>
            )
          }
          <Col
            xs={12}
            md={hasSidebar ? 8 : 12}
            xl={hasSidebar ? 9 : 12}
            //@ts-ignore
            component='main'
          >
            {children}
          </Col>
        </Row>
      </Container>
      {
        contact && (
          <Contact
            //@ts-ignore
            contact={contact}
          />
        )
      }
    </S.PageLayout>
  )
};

PageLayout.propTypes = {
  data: PropTypes.any,
  heroProps: PropTypes.object,
};

PageLayout.defaultProps = {
  heroProps: {},
};

export default PageLayout;

export const query = graphql`
    fragment BasePage on SanityLandingPageOrPage {
      ... on SanityPage {
        hero {
          ...HeroFragment
        }
        title {
          localized
        }
        meta {
          ...MetaFragment
        }
        contact {
          ...ContactFragment
        }
        palette
      }

      ... on SanityLandingPage {
        hero {
          ...HeroFragment
        }
        title {
          localized
        }
        meta {
          ...MetaFragment
        }
        contact {
          ...ContactFragment
        }
      }
    }

    fragment CtaData on SanityCta {
      text
      externalLink
      internalLink: _rawInternalLink(resolveReferences: { maxDepth: 1 })
    }
`
