import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby';
import _get from 'lodash/get';
import { ArticleJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Section } from 'components/Layout/styles';
import PageLayout from 'components/Layout/PageLayout';
import withLocalization from 'decorators/withLocalization';

import NewsBox from 'modules/News/NewsBox';
import { useSeo, useOpenGraph } from 'modules/News/utils';
import _merge from 'lodash/merge';
import { LanguageSwitcherContext } from 'contexts/LanguageSwitcherContext'

const NewsSingle = ({
  data: rawData,
  location,
  pageContext,
}) => {
  const data = _get(rawData, 'sanityNews');
  const seo = useSeo(data);
  const openGraph = useOpenGraph(data);
  const { i18n } = useTranslation();

  const { setForceTranslatedUrls } = useContext(LanguageSwitcherContext)

  useEffect(() => {
    setForceTranslatedUrls(pageContext.translations)
  }, [setForceTranslatedUrls, pageContext])

  const heroProps = {
    type: 'polygon',
    heading: null,
  }

  return (
    <React.Fragment>
      <ArticleJsonLd
        url={location.href}
        {...seo}
      />
      <GatsbySeo
        {
          ..._merge({
            openGraph: {
              locale: i18n.language,
              url: location.href,
            }
          }, openGraph)
        }
      />
      <PageLayout data={data} heroProps={heroProps}>
        <Section>
          <NewsBox news={data} />
        </Section>
      </PageLayout>
    </React.Fragment>
  )
};

export const query = graphql`
  query ReadNews($newsId: String, $language: String!) {
    sanityNews(id: { eq: $newsId}) {
      title {
        de
        en
        localized
      }
      hero {
        ...HeroFragment
      }
      meta {
        ...MetaFragment
      }
      date
      _rawContent(resolveReferences: { maxDepth: 10 })
      topics
    }

    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withLocalization(NewsSingle);
