import _get from 'lodash/get';

import { toPlainText, getImage } from 'utils/sanity';
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Topics = [
  { title: 'Advanced Management', value: 'Advanced Management'},
  { title: 'Corporate Transformation', value: 'Corporate Transformation'},
  { title: 'Digital Transformation', value: 'Digital Transformation'},
  { title: 'Organizational Leadership', value: 'Organizational Leadership'},
  { title: 'Personal Leadership', value: 'Personal Leadership'},
  { title: 'Leadership', value: 'Leadership'},
  { title: 'Entrepreneurship', value: 'Entrepreneurship'},
  { title: 'Finance', value: 'Finance'},
  { title: 'Accounting', value: 'Accounting'},
  { title: 'Controlling', value: 'Controlling'},
  { title: 'General Management', value: 'General Management'},
  { title: 'Innovation', value: 'Innovation'},
  { title: 'Management Essentials', value: 'Management Essentials'},
  { title: 'Marketing', value: 'Marketing'},
  { title: 'Sales', value: 'Sales'},
  { title: 'Negotiations', value: 'Negotiations'},
  { title: 'Non-profit', value: 'Non-profit'},
  { title: 'Social Impact', value: 'Social Impact'},
  { title: 'Supply Chain Operations', value: 'Supply Chain Operations'},
  { title: 'Strategy', value: 'Strategy' },
];


export const useSeo = (article) => {
  const imageId = _get(article, 'hero.image.asset._id');
  const image = imageId && getImage(imageId).width(1920).url();

  return {
    headline: article.title.localized,
    datePublished: article.date,
    body: toPlainText(article._rawContent),
    authorType: 'Organization',
    publisherName: 'WHU - Otto Beisheim School of Management – Executiv e Education',
    publisherLogo: 'https://ee.whu.edu/logo.svg',
    keywords: article.topcis,
    authorName: 'Yvonne Krack',
    images: [image],
  };
};

export const useOpenGraph = (article) => {
  const { i18n } = useTranslation()
  const imageId = _get(article, 'hero.image.asset._id');
  const image = imageId && getImage(imageId).width(800).height(600).url();

  return {
    title: article.title.localized,
    description: article?.meta?.description?.[i18n.language] ?? toPlainText(article._rawContent),
    openGraph: {
      title: article.title.localized,
      description: article?.meta?.description?.[i18n.language] ?? toPlainText(article._rawContent),
      article: {
        tags: article.topics,
        publishedTime: article.date,
      },
      images: image ? [
        {
          width: 800,
          height: 600,
          url: image,
          alt: article.title.localized,
        }
      ] : undefined,
    },
  };
}
