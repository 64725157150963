import styled from 'styled-components';

export const Tag = styled.span`
  border-radius: 18px;
  padding: 4px 16px;
  font-size: 1.4rem;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  
  color: ${(props) => props.color ? props.theme.palette.white : 'inherit'};
  background: ${(props) => props.theme.palette[props.color] || props.theme.palette.paperBeta};
  
  &:hover {
    color: ${(props) => props.theme.palette.primary};
  }
`;
