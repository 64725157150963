export const palette = {
  variant: 'tertiaryAlpha',
  accent: 'tertiaryBeta',
};

const MS_DAY = 1000 * 60 * 60 * 24;

const today = new Date().getTime();

const conferenceDiffDays = (date) => {
  const d = new Date(date);
  const utc = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());

  return Math.floor((today - utc) / MS_DAY);
};

export const isPastConference = (conference) => {
  const days = conferenceDiffDays(conference.startDate);

  return days >= 0;
}

export const isUpcomingConference = (conference) => {
  const days = conferenceDiffDays(conference.startDate);

  return days < 0;
}
