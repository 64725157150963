import React from 'react';
import PropTypes from 'prop-types';

import { AvailableColors } from '../ThemeProvider'

import * as S from './styles';

const Tag = ({
  color,
  value,
  onClick,
}) => {
  return (
    <S.Tag color={color} onClick={(ev) => {
      ev.stopPropagation();
      onClick()
    }}>
      {value}
    </S.Tag>
  )
};

Tag.propTypes = {
  color: PropTypes.oneOf(AvailableColors),
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export { S as TagStyles };
export default Tag;
